window.addEventListener('load', function(event) {
  var filterToggleButtons = document.querySelectorAll("[data-filter-toggle-button]");

  [].forEach.call(filterToggleButtons, function(toggleButton) {
    toggleButton.addEventListener('click', function(e) {
      var filterName = e.srcElement.dataset.filterToggleButton;
      var filter = document.querySelector("[data-filter='" + filterName + "']");
      filter.classList.toggle('filter-block--open');
    });
  });
});
