window.addEventListener('load', function(event) {
  var options = {
    valueNames: ['recipe-list__title', 'course', 'type', 'cuisine', 'diet', 'time'],
    pagination: true,
    page: 10,
    fuzzySearch: {
      searchClass: "recipe-list__search",
    }
  };

  var recipeList = new List('recipe-list', options);

  var radioButtons = document.querySelectorAll('input[name=course], input[name=type], input[name=cuisine], input[name=diet], input[name=time]');

  [].forEach.call(radioButtons, function(radioButton) {
    radioButton.addEventListener('change', function(e) {
      updateList();
    });
  });

  function updateList() {
    var course = document.querySelector('input[name=course]:checked');
    var courseValue = course ? course.value : 'all';
    var type = document.querySelector('input[name=type]:checked');
    var typeValue = type ? type.value : 'all';
    var cuisine = document.querySelector('input[name=cuisine]:checked');
    var cuisineValue = cuisine ? cuisine.value : 'all';
    var diet = document.querySelector('input[name=diet]:checked');
    var dietValue = diet ? diet.value : 'all';
    var time = document.querySelector('input[name=time]:checked');
    var timeValue = time ? time.value : 'all';

    recipeList.filter(function(item) {
      return (courseValue == 'all' || item.values().course == courseValue)
          && (typeValue == 'all' || item.values().type == typeValue)
          && (cuisineValue == 'all' || item.values().cuisine == cuisineValue)
          && (dietValue == 'all' || item.values().diet == dietValue)
          && (timeValue == 'all' || item.values().time == timeValue)
    });
  }
});
